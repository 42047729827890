.item {
  /* border: 2px solid red; */
  border-radius: 10px;
  width: 50%;
  margin: 10px;
  padding: 10px;
  text-align: left;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.3s;
}

.item:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.19);
}

.title {
  display: inline-flex;
  font-weight: bold;
}

.story {
  /* border: 2px solid green; */
  white-space: pre-wrap;
}

.piece {
  margin-right: 10px;
}

.item.red {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.item.green {
  background: radial-gradient(#60efbc, #58d5c9);
}

.item.blue {
  background: radial-gradient(#1fe4f5, #3fbafe);
}